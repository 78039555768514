/* eslint-disable max-len */

// TODO: After the crowdin keys are ready, Remove sentences translated by i18n
module.exports = {
  ragnar: {
    basePath: '/',
    server: {
      port: 8080,
      securePort: 8443,
      host: '0.0.0.0',
      static: './build',
    },
    middlewares: {
      platform: true, // Ejecuta el middleware platform-detection en scopes que no sea cross-site
      showPrettyErrors: false,
    },
    // eslint-disable-next-line
    version: require(`${__dirname}/../package.json`).version,
  },
  i18n: {
    app: 'fury_developers-site-cms',
    project: 'developer-center',
    translationProject: 'developer-center',
    srcPath: `${__dirname}/../app`,
  },
  defaultText: {
    pt_br: {
      defaultTitleTag: 'Developers',
      defaultDescriptionTag: 'Developers Mercado Livre',
      defaultImgTag: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/326111443501-cover-devsite.jpg',
      indexTitle: 'Conteúdos',
      commons: {
        name: 'Nome',
        nickname: 'Apelido',
        email: 'E-mail',
        send: 'Inscrever-se',
        reset: 'Limpar',
        language: 'Idioma',
        companyName: 'Ebazar.com.br LTDA.',
      },
      password: {
        passwordTitle: 'Este conteúdo está protegido por senha. Para vê-lo, digite sua senha abaixo:',
        passwordLabel: 'Senha',
        passwordButton: 'Entrar',
        passwordMessage: 'Senha incorreta',
      },
      header: {
        search: 'Buscar',
        logIn: 'Entre',
        myApps: 'Minhas aplicações',
        news: 'Novidades',
        support: 'Suporte',
        exit: 'Sair',
        searchBar: 'Buscar...',
        tools: 'Ferramentas',
        hello: 'Olá,',
      },
      footer: {
        footerCall: 'Faça parte da nossa comunidade',
        termsAndConditions: 'Termos e condições',
        privacy: 'Como cuidamos da sua privacidade',
        report: 'Denúncias',
        url: {
          termsAndConditions: 'https://developers.mercadolivre.com.br/pt_br/termos-e-condicoes',
          privacy: 'https://www.mercadolivre.com.br/privacidade',
        },
        user: {
          login: 'Entre',
          loginAs: 'Você entrou como',
          register: 'Registre-se',
        },
        bugBountyText: 'Security Researchers',
      },
      home: {
        events: 'Eventos',
        lastNewsCall: 'Últimas Novidades',
        newsLetterTitle: 'Fique informado',
        newsLetterDescription: 'Receba as últimas notícias sobre nossa API',
        newsLetterWhenUserNotLogged1: 'Faça login',
        newsLetterWhenUserNotLogged2: ' ou ',
        newsLetterWhenUserNotLogged3: 'registre-se',
        newsLetterWhenUserNotLogged4: ' para receber as últimas notícias sobre nossa API',
        somethingWentWrong: 'Não foi possível fazer sua inscricão.',
        subscribeSuccess: 'E-mail inscrito com sucesso',
        emailInvalid: 'E-mail inválido',
        emailExists: 'E-mail já cadastrado',
        unauthorized: 'Não autorizado',
        userSubscribed: 'Você já está cadastrado para receber nossas novidades das API! Para parar de recebê-las,',
        newsLetterSubscribe: 'Inscreva-se em nosso Newsletter',
        newsLetter: 'Receba as últimas novidades sobre nuestra API',
      },
      search: {
        results: 'Resultados da busca por',
        noResults: 'Nenhum resultado encontrado por',
        next: 'Próximo',
        previous: 'Anterior',
      },
      news: {
        mainTitle: 'Novidades relevantes',
        subtitle: 'Descrubra as últimas novidades e recursos que temos disponíveis.',
        pageTitle: 'Novidades destacadas',
        news: 'Novidades',
        results: 'Últimas notícias',
        noResults: 'Nenhum notícia',
        dontWannaRecieveAnymore: 'Não quer mais receber estes e-mails?',
        tellUsWhy: 'Conte-nos o porquê, assim sabemos como melhorar a experiência dos nossos usuários.',
        tooOften: 'Eu os recebo com muita frequência',
        notInteresting: 'Não me interessam',
        otherReason: 'Outro motivo',
        unsubscribe: 'Cancelar inscrição',
        unsubDone: 'Pronto!',
        unsubResultSuccess: 'Não te enviaremos mais comunicações de developers. Lembre-se de que você pode se inscrever a qualquer momento utilizando nosso',
        devsite: 'devsite',
        unsubError: 'Ops! Houve um erro',
        unsubErrorDesc: 'Não conseguimos fazer isso nesse momento :(',
        goToHome: 'Ir ao início',
        lastUpdate: 'Última atualização em',
        labelSearch: 'Buscar nota ou categoria …',
        titleOportunities: 'Oportunidades',
        titleNewResource: 'Novo recurso',
        titleAboutAPI: 'Novidades',
        btnSubscribe: 'Mais informação',
        linkSeeAll: 'ver todas',
        titleNotCompliant: 'Antes de continuar, deverá completar',
        titleNotCompliantSecond: 'seus dados para continuar operando!',
        bodyNotCompliant: 'Precisamos que você complete a informação de sua conta. Tenha o seu documento em mãos, levará apenas alguns minutos.',
        textButtonStart: 'Começar',
        textLinkWhyCompleteMyData: 'Por que devo completar os meus dados?',
        titleBackoffice: 'Revisamos os seus dados e precisamos',
        titleBackofficeSecond: 'pedir mais informações',
        principalTextBodyBackoffice: 'Complete a informação pelo Mercado Livre ou Mercado Pago:',
        firstBulletTextBodyBackoffice: 'Entre em sua conta pelo app ou por um computador.',
        secondBulletTextBodyBackoffice: 'Busque a notificação ',
        secondBulletTextBodyBackofficePartTwo: '"Já revisamos seus dados".',
        thridBulletTextBodyBackoffice: 'Siga os passos',
        textButtonBackofficeMl: 'Ir para o Mercado Livre',
        textButtonBackofficeMp: 'Ir para o Mercado Pago',
        textCongratsTitle: 'Já estamos verificando',
        textCongratsTitleSecond: 'sua documentação',
        textCongratsTimeVerify: 'Isto pode demorar até 3 dias úteis.',
        textCongratsReady: 'Avisaremos quando estiver pronto.',
        textCongratsButtonRedirectHome: 'Ir para o início',
        section: 'Changelog',
        titleEvents: 'Eventos',
        seeAll: 'ver todos',
        month: 'Mês',
        businessUnit: 'Unidade de negocio',
        category: 'Categoria',
        typeNew: 'Tipo de novidade',
        mshops: 'Mercado Shops',
        mlibre: 'Mercado Livre',
        mads: 'Mercado Ads',
        menvios: 'Mercado Envios',
        typeNewsHighlight: 'Destacada',
        newsTypeNew: 'Novo',
        typeNewsChanged: 'Mudar',
        typeNewsDeprecated: 'Descontinuada',
      },
      events: {
        results: 'Eventos',
        noResults: 'Nenhum evento',
        eventsPast: 'Eventos passados',
        eventsNext: 'Próximos eventos',
      },
      notFound: {
        title: 'Parece que a página não existe.',
        text: 'Voltar',
      },
      alerts: {
        sdkOperator: 'Você não pode acessar sendo operador ',
        invalidAppId: 'Id da aplicação inválida',
      },
      unsubscribe: {
        confirmation: 'Você tem certeza de que quer cancelar o recebimento de e-mails?',
        consequences:
          'Ao cancelar nossos e-mails, você não irá mais receber nossas novidades, informações sobre sua aplicação, mudanças na API e eventos.',
        dontWorry: 'Não se preocupe! Você ainda poderá ver todas as informações sobre sua aplicação na',
        applicationsPage: 'Página de aplicações',
        why: 'Qual o motivo do cancelamento?',
        motiveTooManyEmails: 'Eu recebo e-mails demais dessa lista.',
        motiveNoInterest: 'Eu não me interesso pelo conteúdo.',
        motiveNoSubscribe: 'Eu não me inscrevi para essa lista.',
        cancelSubscription: 'Cancelar inscrição',
      },
      gettingStarted: {
        areYouReady: 'Você está pronto para começar a desenvolver?',
        createAnApp: 'Crie uma aplicação e seja parte do nosso ecossistema.',
        linkYourAccount: 'Vincule sua conta do Mercado Livre e comece agora.',
        goToCreateAnApp: 'Ir para criar minha aplicação',
        createFirstApp: 'Crie sua primeira aplicação',
        followOurGuide: 'Siga nosso guia para publicar de forma rápida e simples.',
        goToLinkYourAccount: 'Ir a vincular minha conta',
        manageYourApplications: 'Administre suas aplicações',
        devcenterWhatToDo: 'No DevCenter você pode gerenciar permissões, revisar estatísticas e editar aplicações.',
        goToMyApplications: 'Ver minhas aplicações',
        startNow: 'Começar agora',
      },
      apiDocs: {
        title: 'API Docs',
        titleHighlight: 'Conheça os últimos recursos disponíveis',
        mainGuides: 'Guias principais',
        subtitleGuide: 'Nas documentações seguintes você encontrará toda a informação necessária para começar a desenvolver sua aplicação.',
        viewGuide: 'Veja o guia',
        modules: 'módulos',
      },
      homeApiDocs: {
        title: 'API Docs',
        subtitle: 'Documentação por unidade de negócio',
        subtitleBU: 'Selecione uma unidade de negócio para consultar as informações disponíveis',
      },
      cardsResources: {
        new: 'Nova',
        highlight: 'Destacada',
        title: 'Recursos gerais',
        subtitle: 'Confira este conteúdo para consultar as informações mais importantes sobre nossas APIs.',
        titleApplicationManagement: 'Gestão de aplicações',
        subtitleApplicationManagement: 'Consulte as informações essenciais para trabalhar com nossas APIs',
        spanCard: 'Ver documentação',
        titleResources: 'Recursos Cross',
        subtitleResources: 'Confira os principais recursos das nossas APIs',
        titleSecure: 'Segurança',
        subtitleSecure: 'Consulte as medidas de segurança para trabalhar com nossas APIs',
      },
      twoColumn: {
        tile: 'Documentação do ',
        tileNav: 'Documentação',
        subtitle: 'Confira todas as informações necessárias sobre as APIs ',
        docCross: 'Você pode usar esta documentação para as seguintes unidades de negócio:',
        titleBugBounty: 'Security Researchers',
        subtitleBugBounty: 'Se você acredita ter encontrado uma vulnerabilidade de segurança em algum dos nossos sites, o convidamos e agradecemos muito que a informe ao Mercado Livre.',
      },
      partners: {
        home: 'Início',
        createAccount: 'Crie sua conta',
        signin: 'Entre',
        hello: 'Olá',
        solutions: 'Soluções',
        myData: 'Meu dados',
        logout: 'Sair',
      },
      messageKyc: {
        developers: 'Developers',
        titlePageLandingKyc: 'Landing KYC - Devsite',
        titleLandingKyc: 'Antes de continuar, você deve completar seus dados para seguir operando!',
        subtitleLandingKyc: 'Precisamos que você complete os dados da sua conta. Tenha seu documento por perto, levará só alguns minutos.',
        titleKYC: 'Complete seus dados para manter seus usuários ativos.',
        subTitleKyc: 'Só leva alguns minutos!',
        buttonCompleteData: 'Começar validação',
        warningKycBackofficeTitle: 'Estamos validando seus dados',
        warningKycBackofficeText: 'Vamos te avisar quando estiver tudo pronto para continuar. Leve em consideração que essa validação leva entre 48 e 72 horas.',
        warningKycBackofficeTextLanding: 'Vamos te avisar quando estiver tudo pronto para continuar.',
        backToHome: 'Ir ao início',
        cancelModal: 'Agora não',
        titleNotCompliant: 'Antes de continuar você deverá completar',
        titleNotCompliantSecond: 'seus dados para seguir operando!',
        bodyNotCompliant: 'Precisamos confirmar as informações sobre a sua conta. Tenha seu documento em mãos, levará apenas alguns minutos.',
        textButtonStart: 'Começar',
        textLinkWhyCompleteMyData: 'Por que tenho que preencher meus dados?',
        titleBackoffice: 'Verificamos seus dados e precisamos',
        titleBackofficeSecond: 'solicitar mais informações',
        principalTextBodyBackoffice: 'Preencha as informações através do Mercado Livre ou Mercado Pago:',
        firstBulletTextBodyBackoffice: 'Faça login em sua conta a partir do aplicativo ou de um computador.',
        secondBulletTextBodyBackoffice: 'Procure a notificação',
        secondBulletTextBodyBackofficePartTwo: 'que diz: "Já verificamos seus dados".',
        thridBulletTextBodyBackoffice: 'Siga os passos.',
        textButtonBackofficeMl: 'Ir para Mercado Livre',
        textButtonBackofficeMp: 'Ir para Mercado Pago',
        textCongratsTitle: 'Estamos verificando',
        textCongratsTitleSecond: 'sua documentacão',
        textCongratsTimeVerify: 'Pode demorar até 3 dias úteis.',
        textCongratsReady: 'Vamos te avisar quando estiver tudo pronto.',
        textCongratsButtonRedirectHome: 'Ir ao início',
      },
      isCategory: {
        highlight: 'Destacada',
        new: 'Novo',
        change: 'Atualizada',
        deprecated: 'Deprecado',
      },
    },
    es_ar: {
      defaultTitleTag: 'Developers',
      defaultDescriptionTag: 'Developers Mercado Libre',
      defaultImgTag: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/326111443501-cover-devsite.jpg',
      indexTitle: 'Contenidos',
      commons: {
        name: 'Nombre',
        nickname: 'Nickname',
        email: 'E-mail',
        send: 'Suscribirme',
        reset: 'Limpiar',
        language: 'Idioma',
        companyName: 'MercadoLibre S.R.L.',
      },
      password: {
        passwordTitle:
          'Este contenido está protegido por contraseña. Para verlo introduce tu contraseña a continuación:',
        passwordLabel: 'Contraseña',
        passwordButton: 'Entrar',
        passwordMessage: 'Contraseña incorrecta',
      },
      header: {
        search: 'Buscar',
        logIn: 'Ingresar',
        myApps: 'Mis Aplicaciones',
        news: 'Novedades',
        support: 'Soporte',
        exit: 'Salir',
        searchBar: 'Buscar...',
        tools: 'Herramientas',
        hello: '¡ Hola,',
      },
      footer: {
        footerCall: 'Forma parte de nuestra comunidad',
        termsAndConditions: 'Términos y condiciones',
        privacy: 'Cómo cuidamos tu privacidad',
        report: 'Denunciar',
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.ar/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.ar/privacidad',
        },
        user: {
          login: 'Ingresar',
          loginAs: 'Ingresaste como',
          register: 'Regístrate',
        },
        bugBountyText: 'Security Researchers',
      },
      home: {
        events: 'Eventos',
        lastNewsCall: 'Últimas Novedades',
        newsLetterTitle: 'Mantente informado',
        newsLetterDescription: 'Recibe las últimas novedades sobre nuestra API',
        newsLetterWhenUserNotLogged1: 'Ingrese',
        newsLetterWhenUserNotLogged2: ' o ',
        newsLetterWhenUserNotLogged3: 'regístrate',
        newsLetterWhenUserNotLogged4: ' para recibir las últimas novedades sobre nuestra API',
        subscribeSuccess: 'E-mail inscrito con éxito',
        somethingWentWrong: 'No fue posible completar la suscripción.',
        emailInvalid: 'E-mail inválido',
        emailExists: 'E-mail ya registrado',
        unauthorized: 'No autorizado',
        userSubscribed: '¡Ya estás suscripto a las novedades de nuestras API! Para dejar de recibirlas,',
        newsLetterSubscribe: 'Suscríbete a nuestro Newsletter',
        newsLetter: 'Recibe las últimas novedades sobre nuestra API',

      },
      search: {
        results: 'Resultados de busqueda por',
        noResults: 'Ningun resultado encontrado por',
        next: 'Siguiente',
        previous: 'Anterior',
      },
      news: {
        mainTitle: 'Novedades relevantes',
        subtitle: 'Descubre las últimas novedades y recursos que tenemos disponibles.',
        pageTitle: 'Novedades destacadas',
        news: 'Novedades',
        section: 'Changelog',
        titleEvents: 'Eventos',
        seeAll: 'ver todos',
        month: 'Mes',
        businessUnit: 'Unidad de negocio',
        category: 'Categoría',
        typeNew: 'Tipo de novedad',
        mshops: 'Mercado Shops',
        mlibre: 'Mercado Libre',
        mads: 'Mercado Ads',
        menvios: 'Mercado Envios',
        typeNewsHighlight: 'Destacada',
        newsTypeNew: 'Nuevo',
        typeNewsChanged: 'Cambio',
        typeNewsDeprecated: 'Deprecado',
        results: 'Últimas noticias',
        noResults: 'No hay noticias',
        dontWannaRecieveAnymore: '¿Ya no quieres recibir estos correos electrónicos?',
        tellUsWhy: 'Cuéntanos por qué, para que sepamos cómo mejorar la experiencia de nuestros usuarios.',
        tooOften: 'Los recibo con demasiada frecuencia',
        notInteresting: 'No me interesan',
        otherReason: 'Otro motivo',
        unsubscribe: 'Cancelar suscripción',
        unsubDone: '¡Listo!',
        unsubResultSuccess: 'Ya no te enviaremos comunicaciones de developers. Recuerda que puedes registrarte en cualquier momento utilizando nuestro',
        devsite: 'devsite',
        unsubError: 'Ups! Hubo un error',
        unsubErrorDesc: 'No pudimos hacer eso en ese momento :(',
        goToHome: 'Ir al início',
        lastUpdate: 'Última actualización',
        description: 'Descubre las últimas novedades y recursos que tenemos disponibles.',
        labelSearch: 'Buscar nota o categoría ...',
        titleOportunities: 'Oportunidades',
        titleNewResource: 'Nuevo recurso',
        titleAboutAPI: 'Novedades',
        btnSubscribe: 'Más información',
        linkSeeAll: 'ver todas',
        titleNotCompliant: '¡Antes de continuar deberás completar',
        titleNotCompliantSecond: 'tus datos para poder seguir operando!',
        bodyNotCompliant: 'Necesitamos que completes la información de tu cuenta. Tené tu documentación a mano, te llevará solo unos minutos.',
        textButtonStart: 'Empezar',
        textLinkWhyCompleteMyData: '¿Por qué debo completar mis datos?',
        titleBackoffice: 'Revisamos tus datos y necesitamos',
        titleBackofficeSecond: 'pedirte más información',
        principalTextBodyBackoffice: 'Completa la información desde Mercado Libre o Mercado Pago:',
        firstBulletTextBodyBackoffice: 'Ingresa a tu cuenta desde la app o desde una computadora.',
        secondBulletTextBodyBackoffice: 'Busca la notificación',
        secondBulletTextBodyBackofficePartTwo: 'que dice: "Ya revisamos tus datos".',
        thridBulletTextBodyBackoffice: 'Sigue los pasos.',
        textButtonBackofficeMl: 'Ir a Mercado Libre',
        textButtonBackofficeMp: 'Ir a Mercado Pago',
        textCongratsTitle: 'Ya estamos verificando',
        textCongratsTitleSecond: 'tu documentación',
        textCongratsTimeVerify: 'Esto puede demorar hasta 3 días hábiles.',
        textCongratsReady: 'Te avisaremos cuando esté todo listo.',
        textCongratsButtonRedirectHome: 'Ir al inicio',
      },
      events: {
        results: 'Eventos',
        noResults: 'No hay eventos',
        eventsPast: 'Eventos pasados',
        eventsNext: 'Próximos eventos',
      },
      notFound: {
        title: 'Parece que esta página no existe.',
        text: 'Ir a la página principal',
      },
      alerts: {
        sdkOperator: 'No puedes acceder siendo operador',
        invalidAppId: 'Id de aplicación inválida',
      },
      unsubscribe: {
        confirmation: '¿Está seguro de que desea cancelar la recepción de e-mails?',
        consequences:
          'Al cancelar nuestros e-mails, ya no recibirá nuestras noticias, información sobre su aplicación, cambios en la API y eventos.',
        dontWorry: '¡No te preocupes! Aún puedes ver toda la información sobre tu aplicación en la',
        applicationsPage: 'Página de aplicaciones',
        why: '¿Por qué cancelas?',
        motiveTooManyEmails: 'Recibo muchos e-mails de esta lista.',
        motiveNoInterest: 'No tengo ningún interés en este contenido.',
        motiveNoSubscribe: 'No me he suscrito a esta lista.',
        cancelSubscription: 'Cancelar suscripción',
      },
      gettingStarted: {
        areYouReady: '¿Estás listo para comenzar a desarrollar?',
        createAnApp: 'Crea una aplicación y sé parte de nuestro ecosistema.',
        linkYourAccount: 'Vincula tu cuenta de Mercado Libre y comienza ahora.',
        goToCreateAnApp: 'Ir a crear mi aplicación',
        createFirstApp: 'Crea tu primera aplicación',
        followOurGuide: 'Sigue nuestra guía para publicar ítems de forma rápida y sencilla.',
        goToLinkYourAccount: 'Ir a vincular mi cuenta',
        manageYourApplications: 'Administra tus aplicaciones',
        devcenterWhatToDo: 'En DevCenter podrás administrar permisos, revisar estadísticas y editar aplicaciones.',
        goToMyApplications: 'Ver mis aplicaciones',
        startNow: 'Empezar ahora',
      },
      apiDocs: {
        title: 'API Docs',
        titleHighlight: 'Conoce los últimos recursos disponibles',
        mainGuides: 'Guías principales',
        subtitleGuide: 'En las siguientes documentaciones encontrarás toda la información que necesitas para empezar a desarrollar tu aplicación.',
        viewGuide: 'Ver guía',
        modules: 'módulos',
      },
      homeApiDocs: {
        title: 'API Docs',
        subtitle: 'Documentación por unidad de negocio',
        subtitleBU: 'Selecciona una unidad de negocio para consultar la información disponible',
      },
      cardsResources: {
        new: 'Nuevo',
        highlight: 'Destacada',
        title: 'Recursos generales',
        subtitle: 'Explora este contenido para conocer la información más importante de nuestras APIs.',
        titleApplicationManagement: 'Gestión de aplicaciones',
        subtitleApplicationManagement: 'Consulta la información esencial para trabajar con nuestras APIs',
        spanCard: 'Ver documentación',
        titleResources: 'Recursos Cross',
        subtitleResources: 'Explora los recursos principales de nuestras APIs',
        titleSecure: 'Seguridad',
        subtitleSecure: 'Consulta las prácticas de seguridad para operar nuestras APIs',
      },
      twoColumn: {
        tile: 'Documentación ',
        tileNav: 'Documentación',
        subtitle: 'Descubre toda la información que debes conocer sobre las APIs de ',
        docCross: 'Puedes usar esta documentación para las siguientes unidades de negocio:',
        titleBugBounty: 'Security Researchers',
        subtitleBugBounty: 'Si crees que has encontrado una vulnerabilidad de seguridad en alguno de nuestros sitios web, te invitamos y agradecemos mucho que lo informes a Mercado Libre.',
      },
      partners: {
        home: 'Inicio',
        createAccount: 'Crea tu cuenta',
        signin: 'Ingresá',
        hello: 'Hola',
        solutions: 'Soluciones',
        myData: 'Mis datos',
        logout: 'Salir',
      },
      messageKyc: {
        developers: 'Developers',
        titlePageLandingKyc: 'Landing KYC - Devsite',
        titleLandingKyc: '¡Antes de continuar deberás completar tus datos para poder seguir operando!',
        subtitleLandingKyc: 'Necesitamos que completes la información de tu cuenta. Tené tu DNI a mano, te llevará solo unos minutos.',
        titleKYC: 'Completá ahora tus datos para mantener tus usuarios activos.',
        subTitleKyc: '¡Te llevará sólo unos minutos!',
        buttonCompleteData: 'Empezar Validación',
        warningKycBackofficeTitle: 'Estamos verificando tus datos',
        warningKycBackofficeText: 'Te avisaremos cuando esté todo listo para que puedas continuar. Ten en cuenta que esta validación demora entre 48 y 72 horas.',
        warningKycBackofficeTextLanding: 'Te avisaremos cuando esté todo listo para que puedas continuar.',
        backToHome: 'Ir al inicio',
        cancelModal: 'Ahora no',
        titleNotCompliant: '¡Antes de continuar deberás completar',
        titleNotCompliantSecond: 'tus datos para poder seguir operando!',
        bodyNotCompliant: 'Necesitamos que completes la información de tu cuenta. Ten tu documentación a mano, te llevará solo unos minutos.',
        textButtonStart: 'Empezar',
        textLinkWhyCompleteMyData: '¿Por qué debo completar mis datos?',
        titleBackoffice: 'Revisamos tus datos y necesitamos',
        titleBackofficeSecond: 'pedirte más información',
        principalTextBodyBackoffice: 'Completa la información desde Mercado Libre o Mercado Pago:',
        firstBulletTextBodyBackoffice: 'Ingresa a tu cuenta desde la app o desde una computadora.',
        secondBulletTextBodyBackoffice: 'Busca la notificación',
        secondBulletTextBodyBackofficePartTwo: 'que dice: "Ya revisamos tus datos".',
        thridBulletTextBodyBackoffice: 'Sigue los pasos.',
        textButtonBackofficeMl: 'Ir a Mercado Libre',
        textButtonBackofficeMp: 'Ir a Mercado Pago',
        textCongratsTitle: 'Ya estamos verificando',
        textCongratsTitleSecond: 'tu documentación',
        textCongratsTimeVerify: 'Esto puede demorar hasta 3 días hábiles.',
        textCongratsReady: 'Te avisaremos cuando esté todo listo.',
        textCongratsButtonRedirectHome: 'Ir al inicio',
      },
      isCategory: {
        highlight: 'Destacada',
        new: 'Nuevo',
        change: 'Cambio',
        deprecated: 'Deprecado',
      },
    },
    en_us: {
      defaultTitleTag: 'Developers',
      defaultDescriptionTag: 'Developers Mercado Libre',
      defaultImgTag: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/326111443501-cover-devsite.jpg',
      indexTitle: 'Contents',
      commons: {
        name: 'Name',
        nickname: 'Nickname',
        email: 'E-mail',
        send: 'Subscribe',
        reset: 'Reset',
        language: 'Language',
        companyName: 'MercadoLibre S.R.L.',
      },
      password: {
        passwordTitle: 'This content is password protected. To view it please enter your password below:',
        passwordLabel: 'Password',
        passwordButton: 'Enter',
        passwordMessage: 'Incorrect Password',
      },
      header: {
        search: 'Search',
        logIn: 'Enter',
        myApps: 'My Applications',
        news: 'News',
        support: 'Support',
        exit: 'Exit',
        searchBar: 'Search...',
        tools: 'Tools',
        hello: 'Hello',
      },
      footer: {
        footerCall: 'Be part of our community',
        termsAndConditions: 'Terms and conditions',
        privacy: 'How we take care of your privacy',
        report: 'Report',
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.ar/en_us/mercado-libre-developer-terms-and-conditions',
          privacy: 'https://www.mercadolibre.com.ar/privacidad',
        },
        user: {
          login: 'Login',
          loginAs: 'You logged in as',
          register: 'Register',
        },
        bugBountyText: 'Security Researchers',
      },
      home: {
        events: 'Events',
        lastNewsCall: 'Latest news',
        newsLetterTitle: 'Keep updated',
        newsLetterDescription: 'Recieve the latest news about our API',
        newsLetterWhenUserNotLogged1: 'Login',
        newsLetterWhenUserNotLogged2: ' or ',
        newsLetterWhenUserNotLogged3: 'register',
        newsLetterWhenUserNotLogged4: ' to recieve the latest news about our API',
        subscribeSuccess: 'E-mail successfully subscribed',
        somethingWentWrong: 'The subscription could not be completed.',
        emailInvalid: 'Invalid e-mail',
        emailExists: 'E-mail already registered',
        unauthorized: 'Unauthorized',
        userSubscribed: 'You are now subscribed to our API news! To stop receiving them,',
        newsLetterSubscribe: 'Subscribe to our Newletter',
        newsLetter: 'Get the latest news about our API',
      },
      search: {
        results: 'Searching results for',
        noResults: 'No results found for',
        next: 'Next',
        previous: 'Previous',
      },
      news: {
        mainTitle: 'Relevant news',
        subtitle: 'Discover the latest news and resources we have available',
        pageTitle: 'Highlights news',
        news: 'News',
        results: 'Latest news',
        noResults: 'No news',
        dontWannaRecieveAnymore: 'Do you no longer want to receive these emails?',
        tellUsWhy: 'Tell us why, so we know how to improve the experience of our users.',
        tooOften: 'I receive them far too often',
        notInteresting: 'They don\'t interest me',
        otherReason: 'Another motive',
        unsubscribe: 'Unsubscribe',
        unsubDone: 'Done!',
        unsubResultSuccess: 'We will no longer send you communications from developers. Remember that you can register at any time using our',
        devsite: 'devsite',
        unsubError: 'Ops! There\'s an error',
        unsubErrorDesc: 'We can\'t do this right now :(',
        goToHome: 'Go to home',
        lastUpdate: 'Last update',
        description: 'Discover the latest news and resources we have available.',
        labelSearch: 'Search for notes or categories …',
        titleOportunities: 'Opportunities',
        titleNewResource: 'New resource',
        titleAboutAPI: 'News',
        btnSubscribe: 'More information',
        linkSeeAll: 'See all',
        titleNotCompliant: 'Before continuing, you should complete',
        titleNotCompliantSecond: 'your data to keep operating!',
        bodyNotCompliant: 'We need you to complete your account information. Have your document ready, it will only take a few minutes.',
        textButtonStart: 'Get started',
        textLinkWhyCompleteMyData: 'Why should I complete my data?',
        titleBackoffice: 'We have reviewed your data and need to',
        titleBackofficeSecond: 'to request more information',
        principalTextBodyBackoffice: 'Complete the information in Mercado Libre or Mercado Pago:',
        firstBulletTextBodyBackoffice: 'Log in to your account from the app or from a computer.',
        secondBulletTextBodyBackoffice: 'Search for the notification "we have already verified your data',
        secondBulletTextBodyBackofficePartTwo: 'que dice: "Ya revisamos tus datos".',
        thridBulletTextBodyBackoffice: 'Follow the steps',
        textButtonBackofficeMl: 'Go to Mercado Libre',
        textButtonBackofficeMp: 'Go to Mercado Pago',
        textCongratsTitle: "We're already checking",
        textCongratsTitleSecond: 'tu documentación',
        textCongratsTimeVerify: 'This can take up to 3 working days.',
        textCongratsReady: 'We will let you know when everything is ready',
        textCongratsButtonRedirectHome: 'Go to beginning',
        section: 'Changelog',
        titleEvents: 'Events',
        seeAll: 'See all',
        month: 'Month',
        businessUnit: 'Business unit',
        category: 'Category',
        typeNew: 'Tipo de novedad',
        mshops: 'Mercado Shops',
        mlibre: 'Mercado Libre',
        mads: 'Mercado Ads',
        menvios: 'Mercado Envios',
        typeNewsHighlight: 'Highlight',
        newsTypeNew: 'New',
        typeNewsChanged: 'Update',
        typeNewsDeprecated: 'Deprecated',
      },
      events: {
        results: 'Events',
        noResults: 'No events',
        eventsPast: 'Past events',
        eventsNext: 'Next events',
      },
      notFound: {
        title: 'This page does not exist',
        text: 'Go to the main page',
      },
      alerts: {
        sdkOperator: 'Not allowed to access with operator credential',
        invalidAppId: 'Invalid app id',
      },
      unsubscribe: {
        confirmation: 'Are you sure you want to cancel receiving emails?',
        consequences:
          'By canceling our emails, you will no longer receive our news, information about your application, changes to the API and events.',
        dontWorry: "Don't worry! You still can see all information about your app on",
        applicationsPage: 'Applications Page',
        why: 'Why are you canceling?',
        motiveTooManyEmails: 'I receive a lot of emails from this list.',
        motiveNoInterest: 'I have no interest in this content.',
        motiveNoSubscribe: "I don't have subscribed in this newsletter.",
        cancelSubscription: 'Cancel subscription',
      },
      gettingStarted: {
        areYouReady: 'Are you ready to start developing?',
        createAnApp: 'Create an application and be part of our ecosystem.',
        linkYourAccount: 'Link your Mercado Libre account and start now.',
        goToCreateAnApp: 'Go to create my application',
        createFirstApp: 'Create your first application',
        followOurGuide: 'Follow our guide to publish items quickly and easily.',
        goToLinkYourAccount: 'Go to link my account',
        manageYourApplications: 'Manage your applications',
        devcenterWhatToDo: 'In DevCenter you can manage permissions, review statistics and edit applications.',
        goToMyApplications: 'Go to my applications',
        startNow: 'Start now',
      },
      apiDocs: {
        title: 'API Docs',
        titleHighlight: 'Get to know the latest available resources',
        mainGuides: 'Main guides',
        subtitleGuide: 'In the following documentation you will find all the information you need to start developing your application.',
        viewGuide: 'See guide',
        modules: 'modules',
      },
      homeApiDocs: {
        title: 'API Docs',
        subtitle: 'Documentation by business unit',
        subtitleBU: 'Select the business unit to consult the available information.',
      },
      cardsResources: {
        new: 'New',
        highlight: 'Featured',
        title: 'General resources',
        subtitle: 'Explore this content to know the most important information about our APIs.',
        titleApplicationManagement: 'Application management',
        subtitleApplicationManagement: 'Consult the essential information to work with our APIs.',
        spanCard: 'See the documentation',
        titleResources: 'Cross resources',
        subtitleResources: 'Explore the main resources of our APIs.',
        titleSecure: 'Security',
        subtitleSecure: 'Check out the security practices for operating our APIs.',
      },
      twoColumn: {
        tile: 'Documentation ',
        tileNav: 'Documentation',
        subtitle: 'Check out all the necessary information about APIs',
        docCross: 'Message: You can use this documentation for the following business units:',
        titleBugBounty: 'Security Researchers',
        subtitleBugBounty: 'If you believe you have found a security vulnerability in one of our websites, we welcome and greatly appreciate you reporting it to Mercado Libre',
      },
      partners: {
        home: 'Home',
        createAccount: 'Create your account',
        signin: 'Sign in',
        hello: 'Hello',
        solutions: 'Solutions',
        myData: 'My data',
        logout: 'Logout',
      },
      messageKyc: {
        developers: 'Developers',
        titlePageLandingKyc: 'Landing KYC - Devsite',
        titleLandingKyc: 'Before continuing, you must fill in your details to continue operating!',
        subtitleLandingKyc: 'We need you to complete your account information. Having your document nearby, will only take a few minutes.',
        titleKYC: 'Complete your data to keep your users active.',
        subTitleKyc: 'It only takes a few minutes!',
        buttonCompleteData: 'Start validation',
        warningKycBackofficeTitle: 'We are validating your data.',
        warningKycBackofficeText: 'We will let you know when everything is ready to continue. Please note that this validation takes between 48 and 72 hours.',
        warningKycBackofficeTextLanding: 'We will let you know when everything is ready to continue.',
        backToHome: 'Back Home',
        cancelModal: 'Not now',
        titleNotCompliant: 'Fill in your account details',
        titleNotCompliantSecond: 'to continue using it',
        bodyNotCompliant: 'In order to access your applications, create tickets or view our documentation, you must have your complete account details. We recommend that you do it now to be able to continue operating.',
        textButtonStart: 'Start',
        textLinkWhyCompleteMyData: 'Why do I have to fill in my details?',
        titleBackoffice: 'We review your data and we need',
        titleBackofficeSecond: 'ask you for more information',
        principalTextBodyBackoffice: 'Complete the information from Mercado Libre or Mercado Pago:',
        firstBulletTextBodyBackoffice: 'Login to your account from the app or from a computer.',
        secondBulletTextBodyBackoffice: 'Look for the notification',
        secondBulletTextBodyBackofficePartTwo: 'that says: "We have already reviewed your data."',
        thridBulletTextBodyBackoffice: 'Follow the steps.',
        textButtonBackofficeMl: 'Go to Mercado Libre',
        textButtonBackofficeMp: 'Go to Mercado Pago',
      },
      isCategory: {
        highlight: 'Highlight',
        new: 'New',
        change: 'Change',
        deprecated: 'Deprecated',
      },
      isAvailableCategoriesNews: [],
    },
  },
  fendConfig: {
    MLStaticUrl: 'https://http2.mlstatic.com/storage/developers-site-cms/',
    // "Authentication and Authorization" pages ids
    endPointSite: 'ML',
    endPointSitePartners: 'PAR',
    MLA: {
      coreUrl: 'https://www.mercadolibre.com.ar',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.ar',
      myAccountProfileMp: 'https://www.mercadopago.com.ar',
      defaultTitle: 'Mercado Libre Argentina',
      companyName: 'MercadoLibre S.R.L.',
      vendedoresUrl: 'https://vendedores.mercadolibre.com.ar',
      pathDocKyc: '/nota/preguntas-frecuentes-validacion-de-datos',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com.ar/',
        support: 'https://developers.mercadolibre.com.ar/support',
        doc: 'https://developers.mercadolibre.com.ar/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.ar/devcenter',
        news: 'https://developers.mercadolibre.com.ar/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.ar/registration',
        registrationCompany: 'https://registration.mercadolibre.com.ar/registration-company?mode=company&strict=true',
        myAccount: 'https://myaccount.mercadolibre.com.ar/profile#menu-user',
        partners: 'https://partners.mercadolibre.com.ar/',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.ar',
        foro: 'https://developersforum.mercadolibre.com.ar/',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.ar/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.ar/privacidad',
        },
        partners: {
          termsAndConditions: 'https://partners.mercadolibre.com.ar/es_ar/tyc-mla',
        },
      },
    },
    MPE: {
      coreUrl: 'https://www.mercadolibre.com.pe',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.pe',
      myAccountProfileMp: 'https://www.mercadopago.com.pe',
      defaultTitle: 'Mercado Libre Perú',
      companyName: 'MercadoLibre Perú S.R.L.',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.pe/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.pe/devcenter',
        news: 'https://developers.mercadolibre.com.pe/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.pe/registration',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.pe/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.pe/privacidad',
        },
      },
    },
    MPT: {
      companyName: 'MercadoLivre.com Atividades de Internet LTDA',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLivre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolivre.com.pt/support',
        doc: 'https://developers.mercadolibre.com/pt_br/api-docs-pt-br/',
        myApps: 'https://developers.mercadolivre.com.pt/devcenter',
        news: 'https://developers.mercadolivre.com.pt/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.pt/registration',
      },
      tag: {
        title: 'Developers Mercado Livre',
        description: 'Mercado Livre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolivre.com.pt/pt_br/terminos-e-condicoes',
          privacy: 'https://www.mercadolivre.com.pt/privacidade',
        },
      },
    },
    MRD: {
      coreUrl: 'https://www.mercadolibre.com.do',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.do',
      myAccountProfileMp: 'https://www.mercadopago.com.do',
      defaultTitle: 'Mercado Libre Dominicana',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.do/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.do/devcenter',
        news: 'https://developers.mercadolibre.com.do/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.do/registration',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.do/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.do/privacidad',
        },
      },
    },
    MPA: {
      coreUrl: 'https://www.mercadolibre.com.pa',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.pa',
      myAccountProfileMp: 'https://www.mercadopago.com.pa',
      defaultTitle: 'Mercado Libre Panamá',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.pa/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.pa/devcenter',
        news: 'https://developers.mercadolibre.com.pa/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.pa/registration',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.pa/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.pa/privacidad',
        },
      },
    },
    MEC: {
      coreUrl: 'https://www.mercadolibre.com.ec',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.ec',
      myAccountProfileMp: 'https://www.mercadopago.com.ec',
      defaultTitle: 'Mercado Libre Ecuador',
      companyName: 'MercadoLibre Ecuador Cia. Ltda.',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.ec/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.ec/devcenter',
        news: 'https://developers.mercadolibre.com.ec/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.ec/registration',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.ec/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.ec/privacidad',
        },
      },
    },
    MCR: {
      coreUrl: 'https://www.mercadolibre.co.cr',
      myAccountProfileMl: 'https://myaccount.mercadolibre.co.cr',
      myAccountProfileMp: 'https://www.mercadopago.co.cr',
      defaultTitle: 'Mercado Libre Costa Rica',
      companyName: 'MercadoLibre Costa Rica S.R.L.',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.co.cr/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.co.cr/devcenter',
        news: 'https://developers.mercadolibre.co.cr/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.cr/registration',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.co.cr/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.co.cr/privacidad',
        },
      },
    },
    MLU: {
      coreUrl: 'https://www.mercadolibre.com.uy',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.uy',
      myAccountProfileMp: 'https://www.mercadopago.com.uy',
      defaultTitle: 'Mercado Libre Uruguay',
      companyName: 'DeRemate.com de Uruguay S.R.L.',
      vendedoresUrl: 'https://vendedores.mercadolibre.com.uy',
      pathDocKyc: '/nota/preguntas-frecuentes-validacion-de-datos',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com.uy/',
        support: 'https://developers.mercadolibre.com.uy/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.uy/devcenter',
        news: 'https://developers.mercadolibre.com.uy/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.uy/registration',
        registrationCompany: 'https://registration.mercadolibre.com.uy/registration-company?mode=company&strict=true',
        myAccount: 'https://myaccount.mercadolibre.com.uy/profile#menu-user',
        partners: 'https://partners.mercadolibre.com.uy/es_ar/landing-consultoras-mlu',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.uy',
        foro: 'https://developersforum.mercadolibre.com.uy/',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.uy/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.uy/privacidad',
        },
        partners: {
          termsAndConditions: 'https://partners.mercadolibre.com.uy/es_ar/tyc-consultant-mlu',
        },
      },
    },
    MLM: {
      coreUrl: 'https://www.mercadolibre.com.mx',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.mx',
      myAccountProfileMp: 'https://www.mercadopago.com.mx',
      defaultTitle: 'Mercado Libre México',
      companyName: 'DeRemate.com de México S. de R.L. de C.V.',
      vendedoresUrl: 'https://vendedores.mercadolibre.com.mx',
      pathDocKyc: '/nota/preguntas-frecuentes-validacion-de-datos',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com.mx/',
        support: 'https://developers.mercadolibre.com.mx/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.mx/devcenter',
        news: 'https://developers.mercadolibre.com.mx/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.mx/registration',
        registrationCompany: 'https://registration.mercadolibre.com.mx/registration-company?mode=company&strict=true',
        myAccount: 'https://myaccount.mercadolibre.com.mx/profile#menu-user',
        partners: 'https://partners.mercadolibre.com.mx/',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.mx',
        foro: 'https://developersforum.mercadolibre.com.mx',

      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.mx/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.mx/privacidad',
        },
        partners: {
          termsAndConditions: 'https://partners.mercadolibre.com.mx/es_ar/tyc-mlm',
        },
      },
    },
    MLC: {
      coreUrl: 'https://www.mercadolibre.cl',
      myAccountProfileMl: 'https://myaccount.mercadolibre.cl',
      myAccountProfileMp: 'https://www.mercadopago.cl',
      defaultTitle: 'Mercado Libre Chile',
      companyName: 'MercadoLibre Chile Ltda.',
      vendedoresUrl: 'https://vendedores.mercadolibre.cl',
      pathDocKyc: '/nota/preguntas-frecuentes-validacion-de-datos',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.cl/',
        support: 'https://developers.mercadolibre.cl/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.cl/devcenter',
        news: 'https://developers.mercadolibre.cl/devcenter/news/',
        registration: 'https://registration.mercadolibre.cl/registration',
        registrationCompany: 'https://registration.mercadolibre.cl/registration-company?mode=company&strict=true',
        myAccount: 'https://myaccount.mercadolibre.cl/profile#menu-user',
        partners: 'https://partners.mercadolibre.cl/es_ar/consultant-mlc',
        redirectMyAccount: 'https://myaccount.mercadolibre.cl',
        foro: 'https://developersforum.mercadolibre.cl/',

      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.cl/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.cl/privacidad',
        },
        partners: {
          termsAndConditions: 'https://partners.mercadolibre.cl/es_ar/tyc-consultant-mlc',
        },
      },
    },
    MCO: {
      coreUrl: 'https://www.mercadolibre.com.co',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.co',
      myAccountProfileMp: 'https://www.mercadopago.com.co',
      defaultTitle: 'Mercado Libre Colombia',
      companyName: 'MercadoLibre Colombia LTDA.',
      vendedoresUrl: 'https://vendedores.mercadolibre.com.co',
      pathDocKyc: '/nota/preguntas-frecuentes-validacion-de-datos',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com.co/',
        support: 'https://developers.mercadolibre.com.co/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.co/devcenter',
        news: 'https://developers.mercadolibre.com.co/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.co/registration',
        registrationCompany: 'https://registration.mercadolibre.com.co/registration-company?mode=company&strict=true',
        myAccount: 'https://myaccount.mercadolibre.com.co/profile#menu-user',
        partners: 'https://partners.mercadolibre.com.co/es_ar/consultant-mco',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.co',
        foro: 'https://developersforum.mercadolibre.com.co',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.co/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.co/privacidad',
        },
        partners: {
          termsAndConditions: 'https://partners.mercadolibre.com.co/es_ar/tyc-consultant-mco',
        },
      },
    },
    MLV: {
      coreUrl: 'https://www.mercadolibre.com.ve',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.ve',
      myAccountProfileMp: 'https://www.mercadopago.com.ve',
      defaultTitle: 'Mercado Libre Venezuela',
      companyName: 'MercadoLibre Venezuela S.R.L. RIF: J-30684267-5.',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com.ve/',
        support: 'https://developers.mercadolibre.com.ve/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.ve/devcenter',
        news: 'https://developers.mercadolibre.com.ve/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.ve/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.ve',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.ve/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.ve/privacidad',
        },
      },
    },
    MLB: {
      coreUrl: 'https://www.mercadolivre.com.br',
      myAccountProfileMl: 'https://myaccount.mercadolivre.com.br',
      myAccountProfileMp: 'https://www.mercadopago.com.br',
      defaultTitle: 'Mercado Livre Brasil',
      companyName: 'Ebazar.com.br LTDA.',
      vendedoresUrl: 'https://vendedores.mercadolivre.com.br',
      pathDocKyc: '/nota/perguntas-frequentes-validacoes-de-dados',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLivre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://pt-br.eventials.com/mercadolibre/groups/mercado-libre-developers/',
        catalog: 'https://appstore.mercadolivre.com.br/',
        support: 'https://developers.mercadolivre.com.br/support',
        doc: 'https://developers.mercadolibre.com/pt_br/api-docs-pt-br/',
        myApps: 'https://developers.mercadolivre.com.br/devcenter',
        news: 'https://developers.mercadolivre.com.br/devcenter/news/',
        registration: 'https://registration.mercadolivre.com.br/registration',
        registrationCompany: 'https://registration.mercadolivre.com.br/registration-company?mode=company&strict=true',
        myAccount: 'https://myaccount.mercadolivre.com.br/profile#menu-user',
        partners: 'https://partners.mercadolivre.com.br/',
        redirectMyAccount: 'https://myaccount.mercadolivre.com.br',
        foro: 'https://developersforum.mercadolivre.com.br/',
      },
      tag: {
        title: 'Developers Mercado Livre',
        description: 'Mercado Livre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolivre.com.br/pt_br/termos-e-condicoes',
          privacy: 'https://www.mercadolivre.com.br/privacidade',
        },
        partners: {
          termsAndConditions: 'https://partners.mercadolivre.com.br/pt_br/tyc-mlb',
        },
      },
    },
    MBO: {
      coreUrl: 'https://www.mercadolibre.com.bo',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.bo',
      myAccountProfileMp: 'https://www.mercadopago.com.bo',
      defaultTitle: 'Mercado Libre Bolivia',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.bo/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.bo/devcenter',
        news: 'https://developers.mercadolibre.com.bo/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.bo/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.bo',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.bo/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.bo/privacidad',
        },
      },
    },
    MPY: {
      coreUrl: 'https://www.mercadolibre.com.py',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.py',
      myAccountProfileMp: 'https://www.mercadopago.com.py',
      defaultTitle: 'Mercado Libre Paraguay',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.py/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.py/devcenter',
        news: 'https://developers.mercadolibre.com.py/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.py/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.py',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.py/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.py/privacidad',
        },
      },
    },
    MGT: {
      coreUrl: 'https://www.mercadolibre.com.gt',
      myAccountProfileMl: 'https://myaccount.mercadolibre.com.gt',
      myAccountProfileMp: 'https://www.mercadopago.com.gt',
      defaultTitle: 'Mercado Libre Guatemala',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.gt/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.gt/devcenter',
        news: 'https://developers.mercadolibre.com.gt/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.gt/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.gt',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.gt/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.gt/privacidad',
        },
      },
    },
    MHN: {
      coreUrl: 'https://www.mercadolibre.com.hn',
      defaultTitle: 'Mercado Libre Honduras',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.hn/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.hn/devcenter',
        news: 'https://developers.mercadolibre.com.hn/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.hn/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.hn',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.hn/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.hn/privacidad',
        },
      },
    },
    MNI: {
      coreUrl: 'https://www.mercadolibre.com.ni',
      defaultTitle: 'Mercado Libre Nicaragua',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.ni/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.ni/devcenter',
        news: 'https://developers.mercadolibre.com.ni/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.ni/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.ni',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.ni/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.ni/privacidad',
        },
      },
    },
    MSV: {
      coreUrl: 'https://www.mercadolibre.com.sv',
      defaultTitle: 'Mercado Libre El Salvador',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.sv/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.sv/devcenter',
        news: 'https://developers.mercadolibre.com.sv/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.sv/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.sv',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.sv/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.sv/privacidad',
        },
      },
    },
    MCU: {
      defaultTitle: 'Mercado Libre Cuba',
      companyName: 'MercadoLibre',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com/',
        support: 'https://developers.mercadolibre.com.cu/support',
        doc: 'https://developers.mercadolibre.com/es_ar/api-docs-es/',
        myApps: 'https://developers.mercadolibre.com.cu/devcenter',
        news: 'https://developers.mercadolibre.com.cu/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.cu/registration',
        redirectMyAccount: 'https://myaccount.mercadolibre.com.cu',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://developers.mercadolibre.com.cu/es_ar/terminos-y-condiciones',
          privacy: 'https://www.mercadolibre.com.cu/privacidad',
        },
      },
    },
    CBT: {
      defaultTitle: 'Mercado Libre',
      companyName: 'MercadoLibre S.R.L.',
      url: {
        facebook: 'https://www.facebook.com/DevelopersMercadoLibre/',
        twitter: 'https://twitter.com/MeliDevelopers',
        instagram: 'https://www.instagram.com/mercadolibredevelopers/',
        youtube: 'https://www.youtube.com/channel/UCjlUzq46HWrEUbIZEo-K06Q?view_as=subscriber',
        eventials: 'https://es.eventials.com/mercadolibre/groups/mercado-libre-developers',
        catalog: 'https://appstore.mercadolibre.com.ar/',
        support: 'https://global-selling.mercadolibre.com/support',
        doc: 'https://global-selling.mercadolibre.com/devsite/api-docs/',
        myApps: 'https://global-selling.mercadolibre.com/devcenter',
        news: 'https://global-selling.mercadolibre.com/devcenter/news/',
        registration: 'https://registration.mercadolibre.com.ar/registration',
        redirectMyAccount: 'https://global-selling.mercadolibre.com',
      },
      tag: {
        title: 'Developers Mercado Libre',
        description: 'Mercado Libre Developers Site',
        imgTag: 'https://http2.mlstatic.com/resources/frontend/statics/developers-site-cms/DevSite_share.png',
      },
      footer: {
        url: {
          termsAndConditions: 'https://global-selling.mercadolibre.com/devsite/mercado-libre-global-selling-developer-terms-and-conditions',
          privacy: 'https://www.mercadolibre.com.ar/privacidad',
        },
      },
    },
    apiConfig: {
      url: {
        ApiProdPath: '/devsite',
        ApiTestPath: '/devsite_test',
      },
      furyScopes: {
        production: 'production',
        test: 'test',
      },
      defaultAppsDesktopLimit: 10,
      defaultAppsMobileLimit: 5,
    },
  },
  initiativeName: 'openplatform-devcenter',
  initiativeNameVisUsers: 'openplatform-vis-motors',
  kycSitesAllowed: ['MLA', 'MLB', 'MLM', 'MLC', 'MCO'],
  kycSitesAllowedToVisUsers: ['MLB'],
  usersTypeVis: ['car_dealer', 'real_estate_agency'],
  pathProfileMlAndMp: '/profile',
  availableCategoriesNews: ['Getting started', 'API resources', 'Items', 'Stock & Stores', 'Presales', 'Orders & Shipments', 'Claims & Moderations', 'Post-sale messages', 'Promotions', 'Invoices', 'Metrics & News', 'Mercado Ads', 'Recursos da API', 'Guia para produtos', 'Guia para automóveis', 'Guia para Serviços', 'Guia para Mercado Shops', 'Guia para publicidade', 'Primeros pasos', 'Primeiros passos', 'Guia para imóveis', 'Guía para servicios', 'Guía para vehículos', 'Guía para productos', 'Guía para Mercado Shops', 'Guía para publicidad', 'Recursos de la API', 'Guía para inmuebles'],
};
