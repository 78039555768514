const React = require('react');
const PropTypes = require('prop-types');
const Image = require('nordic/image');
const ArrowIcon = require('../../../../assets/icons/arrowDown');

const defaultUserThumbnail = 'https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/366154775951-svgexport-2@2x.png';

function HeaderView(props) {
  const {
    user = null,
    siteId,
    redirectUrl,
    registrationCompany,
    myAccount,
    categories = [],
    texts,
    userLogged,
  } = props;
  const userName = user.firstName ? user.firstName : '';
  const userImage = user.picture ? user.picture : defaultUserThumbnail;
  const userGreetingsText = `${texts.hello}, ${userName}`;
  const logoutUrl = `https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/logout?go=${encodeURIComponent(redirectUrl)}`;

  return (
    <header role="banner" className="header-nav">
      <nav className="header-nav__wrapper">
        <a className="nav-logo" href="/">
          <span>{siteId === 'MLB' ? 'Parceiros Certificados' : 'Expertos Certificados'}</span>
        </a>
        <ul className="nav-options">
          {categories.map((category) => {
            if (category.visible) {
              return (
                <li key={category.id} className="nav-option">
                  <a href={category.reference}>
                    {category.name}
                  </a>
                </li>
              );
            }
            return null;
          })}
          {userLogged === null ? (
            <>
              <li className="nav-option nav-option__create-account">
                <a
                  href={`${registrationCompany}&confirmation_url=${encodeURIComponent(redirectUrl)}`}
                >
                  {texts.createAccount}
                </a>
              </li>
              <li className="nav-option">
                <a
                  href={`https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/login?platform_id=ml&go=${encodeURIComponent(redirectUrl)}`}
                >
                  {texts.signin}
                </a>
              </li>
            </>
          ) : (
            <li className="nav-option nav-option__user" aria-label="menu">
              <button type="button" className="nav-option__user-toggle-menu">
                <Image src={userImage} alt={userName} />
                <span className="nav-option__user-text">
                  {userName}
                </span>
                <ArrowIcon />
              </button>
              <ul className="menu-user">
                <li className="menu-user__option menu-user__greetings">
                  <Image src={userImage} alt={userName} />
                  <span>
                    {userGreetingsText}
                  </span>
                </li>
                <li className="menu-user__option">
                  <a href="/partnercenter/solutions">
                    {texts.solutions}
                  </a>
                </li>
                <li className="menu-user__option">
                  <a href={myAccount}>
                    {texts.myData}
                  </a>
                </li>
                <li className="menu-user__option">
                  <a href={logoutUrl}>
                    {texts.logout}
                  </a>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

HeaderView.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    firstName: PropTypes.string,
    raw: PropTypes.shape({
      thumbnail: PropTypes.shape({ picture_url: PropTypes.string }),
    }),
  }),
  siteId: PropTypes.string.isRequired,
  texts: PropTypes.objectOf(PropTypes.string).isRequired,
  redirectUrl: PropTypes.string.isRequired,
  registrationCompany: PropTypes.string.isRequired,
  myAccount: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

module.exports = HeaderView;
