/* global window, document */
/**
 * Styles
 */
require('../pages/partners/styles.scss');

/**
 * Polyfills
 */
require('./polyfills');

/**
 * Module dependencies
 */
const React = require('react');
const { AndesProvider } = require('@andes/context');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { hydrateRoot } = require('nordic/hydrate');
const PartnersView = require('../pages/partners/components/PartnersView');
const { setAnalytics } = require('./helpers/analyticsPartners');
const setFacebookPixel = require('./helpers/facebook');

/**
 * Get server state
 */
const preloadedState = window.__PRELOADED_STATE__;
const { locale, device, deviceType, user, translations, location, page, csrfToken } = preloadedState;
const pages = page.path;

/**
 * Set Analytics
 */
function setAnalyticsClient() {
  setAnalytics(location, pages, deviceType, user);
}

const i18n = new I18n({
  translations,
});

hydrateRoot(
  document.getElementById('root-app'),
  <AndesProvider locale={locale} device={device} csrfToken={csrfToken}>
    <I18nProvider i18n={i18n}>
      <PartnersView {...preloadedState} />
    </I18nProvider>
  </AndesProvider>,
  setAnalyticsClient(),
  setFacebookPixel(location, pages),
);
