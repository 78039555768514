const React = require('react');
const PropTypes = require('prop-types');

function Icon({ width = 16, height = 16 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M8.448 9.998L13.244 5.202 12.395 4.353 6.751 9.998 12.4 15.647 13.249 14.798z"
        transform="rotate(-90 10 10)"
      />
    </svg>
  );
}

Icon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

module.exports = Icon;
