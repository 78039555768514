const React = require('react');
const Head = require('nordic/head');
const { useI18n } = require('nordic/i18n');
const config = require('../../../config/default');

const HeadView = (props) => {
  const { i18n } = useI18n();
  const { location, imgTag } = props;
  const defaultImgTag = 'https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/326111443501-cover-devsite.jpg';
  const pageImgTag = imgTag || (config?.defaultText?.[`${location}`]?.defaultImgTag || defaultImgTag);

  return (
    <Head>
      <title>Developers</title>
      <meta name="description" content={i18n.gettext('Developers Mercado Libre')} />
      <meta name="author" content="Open Platform Mercado Libre" />

      {/* <!-- Schema.org markup for Google+ --> */}
      <meta itemProp="name" content="Developers" />
      <meta itemProp="description" content={i18n.gettext('Developers Mercado Libre')} />
      <meta itemProp="image" content={pageImgTag} />

      {/* <!-- Twitter Card data --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@melidevelopers" />
      <meta name="twitter:title" content="Developers" />
      <meta name="twitter:description" content={i18n.gettext('Developers Mercado Libre')} />
      <meta name="twitter:image" content={pageImgTag} />

      {/* <!-- Open Graph data --> */}
      <meta property="og:title" content="Developers" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://developers.mercadolibre.com" />
      <meta property="og:image:secure_url" content={pageImgTag} />
      <meta property="og:description" content={i18n.gettext('Developers Mercado Libre')} />
      <meta property="og:site_name" content="Developers" />

      {
        // TODO Delete webfonts in task 3
      }
      <link href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/300-400-600-700.css" rel="stylesheet" />

      {
        // TODO Delete Fontawesome in task 3
      }
      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet" />
      {
        // Temporary fix favicon and links
      }
      <link
        rel="icon"
        type="image/png"
        href="https://http2.mlstatic.com/ui/navigation/3.0.8/mercadolibre/120x120-precomposed.png"
        sizes="120x120"
      />
      <link rel="apple-touch-icon" href="https://http2.mlstatic.com/ui/navigation/5.9.1/mercadolibre/60x60-precomposed.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="https://http2.mlstatic.com/ui/navigation/5.9.1/mercadolibre/76x76-precomposed.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="https://http2.mlstatic.com/ui/navigation/5.9.1/mercadolibre/120x120-precomposed.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="https://http2.mlstatic.com/ui/navigation/5.9.1/mercadolibre/152x152-precomposed.png" />
      <link rel="stylesheet" type="text/css" href="https://http2.mlstatic.com/ui/navigation/5.9.1/mercadolibre/navigation-desktop.css" media="(min-width: 1024px)" /><link rel="stylesheet" type="text/css" href="https://http2.mlstatic.com/ui/navigation/5.9.1/mercadolibre/navigation-mobile.css" media="(max-width: 1023px)" />
    </Head>
  );
};

module.exports = HeadView;
